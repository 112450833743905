import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

type GrantsStore = {
	open: boolean
	device: {
		[key: string]: any
	}
	params: {
		formType: 'hvac' | 'pv' | null
		houseHeatType?: string | null
		heatingPurpose?: string | null
		annualConsumption?: string | null
		zip: string | null
	}
}

const defaults: GrantsStore = {
	open: false,
	device: {},
	params: {
		houseHeatType: null,
		heatingPurpose: null,
		annualConsumption: null,
		zip: null,
		formType: null
	}
}

const GRANTS_MODAL_CTX = 'GRANTS_MODAL_CTX'

export function setGrantsModalState(data?: GrantsStore) {
	const state = writable<GrantsStore>({
		...defaults,
		...(data && { ...data })
	})
	setContext(GRANTS_MODAL_CTX, state)
	return state
}

export function getGrantsModalState() {
	return getContext<Writable<GrantsStore>>(GRANTS_MODAL_CTX)
}
